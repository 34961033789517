import React from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from '@devstart/react-bootstrap';
import { TFunction } from 'i18next';
import Button from '../../ui/Button';
import { ButtonTypes } from '../../ui/Button/button-types';
import medal from '../../../assets/images/medal.png';

import { closeModal } from '../../../templates/Challenges/redux/actions';
import { hardGoTo } from '../../../redux/actions';
import { isCompletionQuizModalOpenSelector } from '../../../templates/Challenges/redux/selectors';
import { calculateGrade } from '../../../utils/general-functions';
import { User } from '../../../redux/prop-types';
import { userSelector } from '../../../redux/selectors';

type ModalCompleteTrail0CertProps = {
  readonly isOpen: boolean;
  readonly user: User;
  readonly closeModal: () => void;
  readonly navigate: (location: string) => void;
  readonly answers: { realAnswers: number[]; userAnswers: number[] };
  readonly userAttemptFPFCert: number;
};

const mapStateToProps = createSelector(
  userSelector,
  isCompletionQuizModalOpenSelector,
  (user: User, isOpen: boolean) => ({
    user,
    isOpen
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      closeModal: () => closeModal('completionQuiz'),
      navigate: hardGoTo
    },
    dispatch
  );

const returnToLearn = (navigate: (location: string) => void) => {
  navigate('/learn');
};

const ModalContent: React.FC<{
  title: string;
  subtitle: string[];
  buttonText: string;
  buttonAction: () => void;
}> = ({ title, subtitle, buttonText, buttonAction }) => (
  <div className='left-side'>
    <div className='intro-texts'>
      <span className='new-stage-unlocked'>{title}</span>
      {subtitle.map((text, index) => (
        <span key={index} className='subtitle'>
          {text}
        </span>
      ))}
    </div>
    <div className='bottom-area'>
      <Button
        buttonType={ButtonTypes.Primary}
        width='440px'
        onClick={buttonAction}
      >
        {buttonText}
      </Button>
    </div>
  </div>
);

const userApproved = ({
  t,
  grade,
  navigate
}: {
  t: TFunction;
  grade: number;
  navigate: (location: string) => void;
}) => (
  <>
    <ModalContent
      title={t('nacional-project.modal.new-stage-unlocked')}
      subtitle={[
        `${t('nacional-project.modal.congrats')} Você concluiu a trilha!`,
        `${t('nacional-project.modal.result-quiz-your-grade')} ${grade}`,
        `${t('nacional-project.modal.result-quiz-approved-subtitle')}`
      ]}
      buttonText={t('nacional-project.modal.result-quiz-button-back-to-learn')}
      buttonAction={() => returnToLearn(navigate)}
    />
    <div className='right-side'>
      <img src={medal} alt='Medalha de Conclusão' width={354} height={453} />
    </div>
  </>
);

const userAlreadyApproved = ({
  t,
  navigate
}: {
  t: TFunction;
  navigate: (location: string) => void;
}) => (
  <>
    <ModalContent
      title={t('nacional-project.modal.new-stage-unlocked')}
      subtitle={[
        `${t('nacional-project.modal.congrats')} Você concluiu a trilha!`,
        `${t('nacional-project.modal.result-quiz-approved-subtitle')}`
      ]}
      buttonText={t('nacional-project.modal.result-quiz-button-back-to-learn')}
      buttonAction={() => returnToLearn(navigate)}
    />
    <div className='right-side'>
      <img src={medal} alt='Medalha de Conclusão' width={354} height={453} />
    </div>
  </>
);

const userDisapprove = ({
  t,
  grade,
  navigate
}: {
  t: TFunction;
  grade: number;
  navigate: (location: string) => void;
}) => (
  <ModalContent
    title={t('nacional-project.modal.result-quiz-title-fail')}
    subtitle={[
      `${t('nacional-project.modal.result-quiz-disapprove-subtitle-1')}`,
      `${t('nacional-project.modal.result-quiz-your-grade')} ${grade}`,
      `${t('nacional-project.modal.result-quiz-disapprove-subtitle-2')}`
    ]}
    buttonText={t('nacional-project.modal.result-quiz-button-back-to-learn')}
    buttonAction={() => returnToLearn(navigate)}
  />
);

const userAlreadyDisapprove = ({
  t,
  navigate
}: {
  t: TFunction;
  navigate: (location: string) => void;
}) => (
  <ModalContent
    title={t('nacional-project.modal.result-quiz-title-fail')}
    subtitle={[
      `${t('nacional-project.modal.result-quiz-disapprove-subtitle-1')}`,
      `${t('nacional-project.modal.result-quiz-disapprove-subtitle-2')}`
    ]}
    buttonText={t('nacional-project.modal.result-quiz-button-back-to-learn')}
    buttonAction={() => returnToLearn(navigate)}
  />
);

const oneMoreChance = ({
  t,
  grade,
  attempt
}: {
  t: TFunction;
  grade: number;
  attempt: number;
}) => (
  <ModalContent
    title={t('nacional-project.modal.result-quiz-title-fail')}
    subtitle={[
      `${t('nacional-project.modal.result-quiz-fail-subtitle-1')}`,
      `${t('nacional-project.modal.result-quiz-your-grade')} ${grade}`,
      `${t('nacional-project.modal.result-quiz-fail-subtitle-2')}`,
      `${t('nacional-project.modal.result-quiz-fail-subtitle-3', {
        attemptUser: attempt
      })}`
    ]}
    buttonText={t('nacional-project.modal.result-quiz-button-try-again')}
    buttonAction={() => {
      window.location.reload();
    }}
  />
);

const ModalCompleteTrail0Cert: React.FC<ModalCompleteTrail0CertProps> = ({
  isOpen,
  closeModal,
  answers,
  navigate,
  userAttemptFPFCert,
  user
}) => {
  const { t } = useTranslation();
  const grade = calculateGrade(answers.realAnswers, answers.userAnswers);
  const alreadyApproved = user.isFundamentosDaProgramacaoFrontEndCert === true;
  const alreadyDisapprove =
    user.isFundamentosDaProgramacaoFrontEndCert === false &&
    user.attemptFPFCert === 2;

  const renderModalContent = () => {
    if (alreadyApproved) return userAlreadyApproved({ t, navigate });
    if (alreadyDisapprove) return userAlreadyDisapprove({ t, navigate });
    if (grade >= 7) return userApproved({ t, grade, navigate });
    if (userAttemptFPFCert < 2)
      return oneMoreChance({ t, grade, attempt: userAttemptFPFCert });
    return userDisapprove({ t, grade, navigate });
  };

  return (
    <Modal
      animation={false}
      dialogClassName='modal-cta-registration'
      keyboard
      onHide={closeModal}
      show={isOpen}
    >
      <Modal.Body>
        <div className='modal-body-content'>{renderModalContent()}</div>
      </Modal.Body>
    </Modal>
  );
};

ModalCompleteTrail0Cert.displayName = 'ModalCompleteTrail0Cert';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCompleteTrail0Cert);
